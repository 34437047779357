import React, { useState } from 'react'

import {
  addNotification,
  Button,
  Buttons,
  Heading,
  Modal,
  ModalBody,
  Notifications,
  Paragraph
} from '@te-digi/styleguide'

const PatternExampleTallennaLuonnoksena = () => {
  const [isModalOpen1, setIsModalOpen1] = useState(false)

  const onModalOpenClick1 = () => setIsModalOpen1(isModalOpen => !isModalOpen)
  const onModalClose1 = () => setIsModalOpen1(false)
  const onModalClose2 = () => {
    setIsModalOpen1(false)
    addNotification('Luonnos tallennettiin onnistuneesti.', {
      status: 'success'
    })
  }

  return (
    <>
      <Button
        color="primary"
        onClick={onModalOpenClick1}
        variant="outline"
      >
        Tallenna luonnoksena
      </Button>
      <Modal
        ariaLabelledby="modal-heading"
        isOpen={isModalOpen1}
        onRequestClose={onModalClose1}
        size="lg"
      >
        <ModalBody>
          <Heading
            id="modal-heading"
            size={4}
          >
            Tallennetaanko tiedot luonnoksena?
          </Heading>
          <Paragraph>
            Voit jatkaa tallennetun luonnoksen muokkaamista myöhemmin.
          </Paragraph>
          <Buttons
            align="right"
            noMargin
          >
            <Button
              onClick={onModalClose1}
              variant="outline"
            >
              Peruuta
            </Button>
            <Button
              color="primary"
              onClick={onModalClose2}
            >
              Tallenna luonnoksena
            </Button>
          </Buttons>
        </ModalBody>
      </Modal>
      <Notifications />
    </>
  )
}

export { PatternExampleTallennaLuonnoksena }
